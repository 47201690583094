:root {
    --ENTRY_CARD_SIZE: 287px;
}

.container {
    /* width: var(--ENTRY_CARD_SIZE); */
    position: relative;
}

.margin {
    margin: 10px 12px 10px 0px;
}

.img_holder {
    background-color: gainsboro;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 297px;
    width: 297px; */
    /* height: var(--ENTRY_CARD_SIZE);
    width: var(--ENTRY_CARD_SIZE); */
}

.bottom {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.bottom p {
    margin: 0
}

.button_voted {
    border: 1px solid black;
}

.title {
    font-size: 16px;
    margin-bottom: 5px !important;
}

.text_container {
    max-width: 80%;
}

.text_container_vote {
    max-width: 70%;
}
.container_size_large {
    width: 380px;
}
.container_size_small {
    width: 287px;
}
.entry_card_size_large {
    height: 380px;
    width: 380px;
}

.entry_card_size_small {
    height: 287px;
    width: 287px;
}
.overlay {
    display: flex;
    /* height: var(--ENTRY_CARD_SIZE);
    width: var(--ENTRY_CARD_SIZE); */
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);  
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    cursor: pointer;
}

.overlay_button {
    width: 100px;
    border: none;
}

.overlay_button:hover {
    opacity: 1;
}

.img_holder:hover + .overlay, .overlay:hover {
    visibility: visible;
    opacity: 1;
}

.company {
    font-style: italic;
    font-size: 16px;
    line-height: 100%;
}

@media screen and (max-width: 1402px) {
    .container_size_large {
        width: 278px;
    }

    .entry_card_size_large {
        height: 278px;
        width: 278px;
    }
}

@media screen and (max-width: 415px) {
    .title {
        max-width: unset;
        width: 100%;
        font-size: 16px;
        word-break: break-word;
    }
    .company {
        max-width: unset;
        width: 100%;
        font-size: 15px;
        margin-bottom: 7px !important;
        word-break: break-word;
    }
    .container {
        width: unset;
    }

    .margin {
        margin: 10px 30px 10px 0px;
    }

    .img_holder {
        /* width: 276px;
        height: 276px; */
    }

    .overlay {
        /* width: 276px;
        height: 276px; */
    }

    .container_min {
        /* width: 38vw; */
        /* margin: 10px 0px 10px 0px; */
    }

    .overlay_min {
        width: 38vw;
        height: 38vw;
    }

    .img_holder_min {
        /* width: 38vw;
        height: 38vw; */
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
        height: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);

    }

    .bottom_min {
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
    }

    .text_container_min {
        max-width: 100%;
        width: 100%
    }
}