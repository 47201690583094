.section {

}

.list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
}

.category {
    width: 604px;
    margin-bottom: 90px;
}

.singleCat {
    width: 25%;
    min-width: 305px;
    margin-bottom: 90px;
}

.tripleCat {
    width: 100%;
    margin-bottom: 90px;
}

.entries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.category_header {
    align-items: flex-end;
    min-height: 70px;
}

.header {
    margin-bottom: 18px;
    width: 594px;
}

.singleHeader {
    width: 297px;
}

@media screen and (max-width: 1395px) {
    /* .list {
        width: 619px;
    } */
}

@media screen and (max-width: 415px) {
    .header {
        margin-bottom: 12px;
        width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }   

    .space {
        width: unset;
    }

    .category {
        width: 100%;
        min-width: unset;
        margin-bottom: 60px;
        /* margin-top: 50px; */
    }

    .entries {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .singleCat {
        margin-bottom: 20px;
        width: unset;
        min-width: unset;
    }

    .singleHeader {
        margin-bottom: 12px;
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
    }

    .list {
        justify-content: space-between;
        width: unset;
    }
}