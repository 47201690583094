.button {
    border:none;
    outline: none;
    opacity: 1;
    transition: all 200ms;
    margin-bottom: 20px;
    background-color: rgba(0,0,0,0);
}

.button:focus {
    outline: none;
}

.button:hover {
    opacity: 0.5;
}

.share_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 64px;
    height: 180px;
    opacity: 0;
    transform: scale(0);
    transform-origin: top;
    transition: all 300ms;
    filter: grayscale();
}

.share_buttons_show {
    opacity: 1;
    transform: scale(1);
    transform-origin: top;
}

.container {
    position: absolute;
    right: var(--CONTAINER_PADDING_RIGHT);
    text-align: right;
    z-index: 100;
}

.spacer {
    height: 40px;
}

@media screen and (max-width: 415px) {
    .container {
        right: var(--CONTAINER_MOBILE_PADDING_RIGHT);
        
    }

    .button {
        height: 30px;
        position: relative;
        top: -10px;
    }

    .icon {
        height: 22px;
    }
    
}