@import url("https://use.typekit.net/ygb2msd.css");
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&display=swap'); */


:root {
    --TRANSITION_EASE_OUT: cubic-bezier(0.17, 0.67, 0.3, 1.01);
    --TRANSITION_DURATION: 250ms;
    --PRIMARY_SHADOW: 0px 3px 5px 0px rgba(0,0,0,0.31);
  
    --PRIMARY_GOLD: #c6a230;
    --BUTTON_GOLD: #C29D25;
    --PRIMARY_TEXT_COLOR: #000000;
    --SECONDARY_TEXT_COLOR: #151313;
    --LIGTH_TEXT_COLOR: #707070;

    --MOBILE_WIDTH: 415px;

    --PRIMARY_BORDER_COLOR: #707070;

    --CONTAINER_PADDING: 0 80px 0 80px;
    --CONTAINER_MOBILE_PADDING: 0px 20px 0 20px; 
    --CONTAINER_PADDING_RIGHT: 80px;
    --CONTAINER_MOBILE_PADDING_RIGHT: 20px;

    --PRIMARY_HEADER_FONT: franklin-gothic-urw, sans-serif;
    --PRIMARY_TEXT_FONT: franklin-gothic-urw, sans-serif;
}

html {
    scroll-padding-top: 200px;
}


hr {
    border-color: #1C1B19;
}

body {
    max-width: 1400px;
}

#root {
    position: absolute;
    max-width: 1400px;
    left: 0;
    right: 0;
    margin: auto;
}

