.container {
    padding: 40px 70px 50px 70px;
    width: 90vw;
}

.header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.category {
    max-width: 467px;
    font-size: 23px;
}

.crowns {
    height: 56px;
    position: absolute;
    top: -75px;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: flex-start; */
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.article_container {
    position: relative;
    margin-bottom: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.article {
    min-width: 218px;
    max-width: 400px;
}

.bold {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 700;
}

@media screen and (max-width: 1090px) {
    .article {
        margin-top: 40px;
        max-width: unset;
    }
    .article_container {
        height: fit-content;
    }
}

.title {
    margin-bottom: 20px;
}

.contents {
    display: flex;
}

.contents p {
    margin-right: 5px;
}

.old_img {

}

.feature_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 625px;
    width: 625px;
}

.img_holder {
    height: 625px;
    width: 625px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.arrow_next {
    transform: rotate(-90deg);
    margin-bottom: 13px;
}

.arrow_prev {
    transform: rotate(90deg);
    margin-bottom: 13px;
}

.dot_container {
    height: 50px;
}

.car_container {
    height: 675px;
    width: 625px;
    text-align: center;
    margin-right: 100px;
    margin-bottom: 30px;
}

.car_container_single {
    text-align: left;
    margin-right: 100px;
}

.car_container li {
    background-color: black !important;
    border: none !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 16px !important;
    opacity: 0.1;
}

.car_container a {
    align-items: flex-end !important;
    width: unset !important;
}

.car_img {
    max-height: 625px;
    max-width: 625px;
    margin: auto;
}

.single_img {
    max-height: 625px;
    max-width: 625px;
    margin: auto;
}

.video {
    width: 625px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.button {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    text-align: right;
}

.button_voted {
    background-color: var(--PRIMARY_GOLD);
}

.nav {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    min-height: 100px;
}

.nav p {
    margin-bottom: 0;
}

.nav_button_container_right {
    margin-left: auto;
    text-align: right;
}

.button_label {
    margin-bottom: 0;
}

.nav_button {
    border: none;
    margin: 0;
    padding: 0;
    /* text-decoration: underline;
    text-decoration-color: var(--PRIMARY_GOLD); */
    background-color: white;
    border-bottom: 1px solid var(--PRIMARY_GOLD);
}

.nav_button_right {
    text-align: right;
}

.nav_button_left {
    text-align: left;
}

.close {
    margin-bottom: 20px;
    text-align: right;
    margin-top: 10px;
    height: 24px;
}

@media screen and (max-width: 415px) {
    .article_container {
        height: fit-content;
        margin-left: 0;
        align-items: flex-start;
    }

    .article {
        margin-top: 20px;
    }

    .arrow_next, .arrow_prev {
        display: none;
    }

    .img_holder, .feature_img {
        height: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
        width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }

    .car_img {
        max-height: 100vw;
        max-width: 100vw;
    }

    .single_img {
        max-width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }

    .car_container {
        /* height: 100vw; */
        height: unset;
        width: 100vw;
        margin-right: unset;
    }

    .container {
        padding: var(--CONTAINER_MOBILE_PADDING);
        width: 100vw;
    }

    .button {
        position: static;
        margin-bottom: 10px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .crowns {
        margin-top: 30px;
        height: 34px;
        position: static;
    }

    .nav_button {
        max-width: 150px;
    }

}