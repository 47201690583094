.placeholder {
    position: relative;
    height: 350px;
}

.container {
    height: 250px;
    max-height: 250px;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 300ms;
    background-color: rgba(255,255,255,0);
    z-index: 1000;
}

.container_minimized {
    /* height: 150px; */
    max-height: 150px;
    overflow: hidden;
}

.logo_minimized {
    opacity: 0;
}

.logo_container_maximized {
    max-height: 126px;
    top: -50px;
}

.logo_container_minimized {
    top: 0;
}

.logo_container {
    position: relative;
    width: 82px;
    max-height: 54px;
    transition: all 300ms;
    cursor: pointer;
}

.logo {
    width: 82px;
    transition: all 300ms;
}

.crown {
    position: absolute;
    width: 81px;
    top: 0;
    left: 0;
}

.border {
    flex-grow: 1;
    height: 2px;
    display: flex;
    justify-content: center;
    margin: 0 73px 0 73px;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 415px) {
    .container {
        height: 140px;
    }

    .container_minimized {
        height: 100px;
    }

    .border {
        /* width: 60px; */
        margin: 0 30px 0 30px;
    }

    .logo_container_maximized {
        max-height: 60px;
        top: -25px;
    }
    
    .logo_container {
        width: 38px;
        max-height: 23px;
    }

    .logo {
        width: 38px;
    }

    .crown {
        width: 38px;
    }

    .button {
        height: 36px;
        line-height: 15px;
        font-size: 15px;
        width: fit-content;
        min-width: 136px;
    }
}