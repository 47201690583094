.footer {
    margin-top: 100px;
}

.logo {
    width: 89px;
}

.logo_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}
.border {
    flex-grow: 1;
    border-bottom: 1px solid black;
    margin: 0 0 0 73px;
}
.container {
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 80px;
    background-color: var(--PRIMARY_GOLD);
}

.nav {
    /* width: 60%; */
    flex-grow: 4;
    margin-bottom: 50px;
}

.link {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 140%;
}

.link:hover {
    text-decoration: none;
    opacity: 0.8;
}

.container a {
    display: block;
}

.content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
}

.content_container > div:nth-child(1) {
    margin-bottom: 50px;
}

.content_container a {
    border-bottom: 1px solid black;
    line-height: 19px;
    margin-bottom: 5px;
    width: fit-content;
    height: 23px;
}

@media screen and (max-width: 415px) {
    .logo {
        width: 48px;
    }

    .container {
        padding: 39px;
    }

    .border {
        margin: 0 0 0 33px;   
    }

    .logo_container {
        height: 73px;
    }
}