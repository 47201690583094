.section h1 {
    color: var(--PRIMARY_GOLD);
    font-size: 60px;
    line-height: 95%;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 150px;
}

.content p {
    margin-right: 20px;
    max-width: 400px;
    min-width: 300px;
    width: 40%;
}

.content div {
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .content {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 415px) {
    .content {
        margin-bottom: 55px;
    }
    .section h1 {
        font-size: 11vw;
    }
    .content p {
        width: unset;
        min-width: unset;
    }

    .content div {
        margin: 10px 0 10px 0;
    }
}
