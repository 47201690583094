.container {
    width: 100%;
    position: relative;
}

.sub_container {
    padding:56.25% 0 0 0;position:relative;
}

.vimeo_iframe {
    position:absolute;top:0;left:0;width:100%;height:100%;
    border: none;
}