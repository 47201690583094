.container {
    min-height: 368px;
    margin-bottom: 126px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0px 75px 0px 75px;
    max-width: 1400px;
    position: relative;
    left: -80px;
}

.calendar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;
    flex-grow: 1;
}

.calendar > div:nth-child(1) {
    color: black;
    margin-right: 70px;
}

.calendar > div:nth-child(2) {
    color: white;
}
.calendar > div:nth-child(1) > p {
    /* color: white; */
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.calendar > div:nth-child(2) > p {
    color: white;
    line-height: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.right {
    text-align: center;
}

.black {
    background-color: black;
}

.gold {
    background-color: var(--PRIMARY_GOLD);
}

.day {
    font-size: 270px;
    line-height: 73%;
    margin: 0;
    letter-spacing: -0.02em;
}

.month {
    margin: 0px;
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: 53px;
    line-height: 100%;
}

.text {
    margin: 0px;
    margin-right: 10px;
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    /* max-width: 250px; */
    min-width: 200px;
    line-height: 100%;
}

.award_text {
    max-width: 300px;
}

.text_container {
    flex-grow: 1;
}

.text_container_left {
    margin-left: 15px;
    flex-grow: 1;
}

.black_text {
    color: #1C1B19;
}

.gold_text {
    color: var(--PRIMARY_GOLD);
}

.ticket_button {
    border: 1px solid var(--PRIMARY_GOLD);
    color: var(--PRIMARY_GOLD);
    background-color: black;
    height: 190px;
    max-width: 407px;
    width: 25vw;
    min-width: 200px;
    font-weight: 700;
}

.right_button {
    background-color: rgba(0,0,0,0);
    border: none;
}

.vote_headline {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 180px;
    letter-spacing: -0.03em;
    margin-right: 5px;
    line-height: 73%;
}

.vote_date {
    
}

.vote_text {
    min-width: 100px;
}

@media screen and (max-width: 1274px) {
    .vote_headline {
        font-size: 150px;
        top: 92px;
    }
}

@media screen and (max-width: 1305px) {
    .vote_headline {
        
    }
    .container {
        padding-bottom: 75px;
        /* justify-content: sp; */
    }
    .left {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .ticket_button {
        max-width: 407px;
        width: 407px;
        min-width: 200px;
    }
}

@media screen and (max-width: 415px) {
    .container {
        position: static;
        width: 100%;
        justify-content: flex-start !important;
    }

    .calendar > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .calendar > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .calendar > div:nth-child(1) > p {
        /* color: white; */
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .calendar > div:nth-child(2) > p {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .text_container_left {
        margin-left: 0px;
    }

    .vote_headline {
        position: relative;
        left: -7px !important;
    }
    .day {
        position: relative;
        left: -15px;
    }
}

@media screen and (max-width: 750px) {
    .container {
        justify-content: center;
        padding: 0px 39px 0px 39px;
        margin-bottom: 60px;
    }
    .left {
        flex-wrap: wrap;
    }
    .day {
        font-size: 240px;
        margin-bottom: 30px;
    }
    .month {
        font-size: 48px;
    }
    .text {
        font-size: 24px;
        max-width: unset;
    }
    .text_container {
        bottom: 0
    }
    .ticket_button {
        max-width: unset;
        width: 257px;
        min-width: unset;
    }
    .vote_headline {
        font-size: 107px;
        line-height: 140px;
        top: 40px;
    }
    .right {
        margin-bottom: 67px;
    }
}


