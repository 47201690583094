.button {
    background-color: white;
    border: none;
    width: fit-content;
    height: fit-content;
    padding: 0px;
    margin: 0;
}

@media screen and (max-width: 415px) {
    .button {
        position: fixed;
        top: 10px;
        right: var(--CONTAINER_MOBILE_PADDING_RIGHT);
        background-color: transparent;
    }

}