.button {
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    opacity: 1;
    transition: all 200ms;
}

.button:focus {
    padding: 5px 15px 5px 15px !important;
}

.button:hover {
    opacity: 0.8;
}

.primary {
    background-color: var(--BUTTON_GOLD);
    color: black;
    border: none;
}

.primary:focus {
    background-color: var(--BUTTON_GOLD) !important;
    color: black !important;
    border: none !important;
}

.secondary {
    background-color: black;
    color: white;
    border: none;
}

.secondary:focus {
    background-color: black !important;
    color: white !important;
    border: none !important;
}

.tertiary {
    background-color: white;
    color: black;
    border-color: black;
}

.tertiary:focus {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
}

.standard {
    min-width: 218px;
    height: 64px;
}

.small {
    min-width: 80px;
    height: 36px;
}

.wide {

}

.text {
    /* font-family: 'Libre Franklin', sans-serif; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.text:focus {
    /* line-height: 64px !important; */
}

.small_text {
    font-size: 14px;
}

@media screen and (max-width: 415px) {
    .standard {
        width: 90vw;
        margin-bottom: 10px;
    }
    .text {
        /* line-height: 30px !important; */
    }
    .text:focus {
        /* line-height: 30px !important; */
    }
    .button:hover {
        opacity: 1;
    }
}