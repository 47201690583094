@import url(https://use.typekit.net/ygb2msd.css);
.winner_gallery_section__20Iqj {

}

.winner_gallery_list__1jDVg {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
}

.winner_gallery_category__3kdwg {
    width: 604px;
    margin-bottom: 90px;
}

.winner_gallery_singleCat__bA61L {
    width: 25%;
    min-width: 305px;
    margin-bottom: 90px;
}

.winner_gallery_tripleCat__1ewm5 {
    width: 100%;
    margin-bottom: 90px;
}

.winner_gallery_entries__2Ojll {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.winner_gallery_category_header__rYOzJ {
    align-items: flex-end;
    min-height: 70px;
}

.winner_gallery_header__2p9_Z {
    margin-bottom: 18px;
    width: 594px;
}

.winner_gallery_singleHeader__3jTNd {
    width: 297px;
}

@media screen and (max-width: 1395px) {
    /* .list {
        width: 619px;
    } */
}

@media screen and (max-width: 415px) {
    .winner_gallery_header__2p9_Z {
        margin-bottom: 12px;
        width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }   

    .winner_gallery_space__2luyI {
        width: unset;
    }

    .winner_gallery_category__3kdwg {
        width: 100%;
        min-width: unset;
        margin-bottom: 60px;
        /* margin-top: 50px; */
    }

    .winner_gallery_entries__2Ojll {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .winner_gallery_singleCat__bA61L {
        margin-bottom: 20px;
        width: unset;
        min-width: unset;
    }

    .winner_gallery_singleHeader__3jTNd {
        margin-bottom: 12px;
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
    }

    .winner_gallery_list__1jDVg {
        justify-content: space-between;
        width: unset;
    }
}
:root {
    --ENTRY_CARD_SIZE: 287px;
}

.entry_card_container__3iBGZ {
    /* width: var(--ENTRY_CARD_SIZE); */
    position: relative;
}

.entry_card_margin__235TZ {
    margin: 10px 12px 10px 0px;
}

.entry_card_img_holder__2MTMU {
    background-color: gainsboro;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 297px;
    width: 297px; */
    /* height: var(--ENTRY_CARD_SIZE);
    width: var(--ENTRY_CARD_SIZE); */
}

.entry_card_bottom__76_wg {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.entry_card_bottom__76_wg p {
    margin: 0
}

.entry_card_button_voted__2ot5s {
    border: 1px solid black;
}

.entry_card_title__2EV2E {
    font-size: 16px;
    margin-bottom: 5px !important;
}

.entry_card_text_container__1DjGN {
    max-width: 80%;
}

.entry_card_text_container_vote__jtcxN {
    max-width: 70%;
}
.entry_card_container_size_large__3ucEz {
    width: 380px;
}
.entry_card_container_size_small__rXk5a {
    width: 287px;
}
.entry_card_entry_card_size_large__3JIUq {
    height: 380px;
    width: 380px;
}

.entry_card_entry_card_size_small__2oGgA {
    height: 287px;
    width: 287px;
}
.entry_card_overlay__1pCqW {
    display: flex;
    /* height: var(--ENTRY_CARD_SIZE);
    width: var(--ENTRY_CARD_SIZE); */
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);  
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

.entry_card_overlay_button__1m0Qc {
    width: 100px;
    border: none;
}

.entry_card_overlay_button__1m0Qc:hover {
    opacity: 1;
}

.entry_card_img_holder__2MTMU:hover + .entry_card_overlay__1pCqW, .entry_card_overlay__1pCqW:hover {
    visibility: visible;
    opacity: 1;
}

.entry_card_company__33zYU {
    font-style: italic;
    font-size: 16px;
    line-height: 100%;
}

@media screen and (max-width: 1402px) {
    .entry_card_container_size_large__3ucEz {
        width: 278px;
    }

    .entry_card_entry_card_size_large__3JIUq {
        height: 278px;
        width: 278px;
    }
}

@media screen and (max-width: 415px) {
    .entry_card_title__2EV2E {
        max-width: unset;
        width: 100%;
        font-size: 16px;
        word-break: break-word;
    }
    .entry_card_company__33zYU {
        max-width: unset;
        width: 100%;
        font-size: 15px;
        margin-bottom: 7px !important;
        word-break: break-word;
    }
    .entry_card_container__3iBGZ {
        width: unset;
    }

    .entry_card_margin__235TZ {
        margin: 10px 30px 10px 0px;
    }

    .entry_card_img_holder__2MTMU {
        /* width: 276px;
        height: 276px; */
    }

    .entry_card_overlay__1pCqW {
        /* width: 276px;
        height: 276px; */
    }

    .entry_card_container_min__2OZhH {
        /* width: 38vw; */
        /* margin: 10px 0px 10px 0px; */
    }

    .entry_card_overlay_min__20WQ6 {
        width: 38vw;
        height: 38vw;
    }

    .entry_card_img_holder_min__11ifs {
        /* width: 38vw;
        height: 38vw; */
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
        height: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);

    }

    .entry_card_bottom_min__9jzB6 {
        width: calc((100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 3)) / 2);
    }

    .entry_card_text_container_min__1BwDn {
        max-width: 100%;
        width: 100%
    }
}
.button_button__2hamv {
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all 200ms;
    transition: all 200ms;
}

.button_button__2hamv:focus {
    padding: 5px 15px 5px 15px !important;
}

.button_button__2hamv:hover {
    opacity: 0.8;
}

.button_primary__8g3S9 {
    background-color: var(--BUTTON_GOLD);
    color: black;
    border: none;
}

.button_primary__8g3S9:focus {
    background-color: var(--BUTTON_GOLD) !important;
    color: black !important;
    border: none !important;
}

.button_secondary__2JR9a {
    background-color: black;
    color: white;
    border: none;
}

.button_secondary__2JR9a:focus {
    background-color: black !important;
    color: white !important;
    border: none !important;
}

.button_tertiary__1W4Pv {
    background-color: white;
    color: black;
    border-color: black;
}

.button_tertiary__1W4Pv:focus {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
}

.button_standard__3G6tp {
    min-width: 218px;
    height: 64px;
}

.button_small__1IgZw {
    min-width: 80px;
    height: 36px;
}

.button_wide__2Z8gH {

}

.button_text__3zbqi {
    /* font-family: 'Libre Franklin', sans-serif; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.button_text__3zbqi:focus {
    /* line-height: 64px !important; */
}

.button_small_text__mJ7X3 {
    font-size: 14px;
}

@media screen and (max-width: 415px) {
    .button_standard__3G6tp {
        width: 90vw;
        margin-bottom: 10px;
    }
    .button_text__3zbqi {
        /* line-height: 30px !important; */
    }
    .button_text__3zbqi:focus {
        /* line-height: 30px !important; */
    }
    .button_button__2hamv:hover {
        opacity: 1;
    }
}
.entry_modal_modal__3XAgg {
    border-radius: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.entry_modal_modal__3XAgg div {
    border-radius: 0;
    max-width: 1280px;
}

@media screen and (max-width: 415px) {
    .entry_modal_modal__3XAgg {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
        padding: 0;
    }
    .entry_modal_modal__3XAgg div {
        border: none;
    }
}
.entry_display_container__3aAFp {
    padding: 40px 70px 50px 70px;
    width: 90vw;
}

.entry_display_header__2q9v8 {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.entry_display_category__3mEuy {
    max-width: 467px;
    font-size: 23px;
}

.entry_display_crowns__3HsBR {
    height: 56px;
    position: absolute;
    top: -75px;
}

.entry_display_section__3Tob2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: flex-start; */
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.entry_display_article_container__2-RVw {
    position: relative;
    margin-bottom: 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.entry_display_article__1lIeP {
    min-width: 218px;
    max-width: 400px;
}

.entry_display_bold__3IFaT {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 700;
}

@media screen and (max-width: 1090px) {
    .entry_display_article__1lIeP {
        margin-top: 40px;
        max-width: unset;
    }
    .entry_display_article_container__2-RVw {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

.entry_display_title__3_vzK {
    margin-bottom: 20px;
}

.entry_display_contents__29DxW {
    display: flex;
}

.entry_display_contents__29DxW p {
    margin-right: 5px;
}

.entry_display_old_img__y-4-- {

}

.entry_display_feature_img__3te01 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 625px;
    width: 625px;
}

.entry_display_img_holder__c7uP2 {
    height: 625px;
    width: 625px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.entry_display_arrow_next__22-Cv {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    margin-bottom: 13px;
}

.entry_display_arrow_prev__1C3vL {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-bottom: 13px;
}

.entry_display_dot_container__27cc2 {
    height: 50px;
}

.entry_display_car_container__1b1xt {
    height: 675px;
    width: 625px;
    text-align: center;
    margin-right: 100px;
    margin-bottom: 30px;
}

.entry_display_car_container_single__3usUP {
    text-align: left;
    margin-right: 100px;
}

.entry_display_car_container__1b1xt li {
    background-color: black !important;
    border: none !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 16px !important;
    opacity: 0.1;
}

.entry_display_car_container__1b1xt a {
    align-items: flex-end !important;
    width: unset !important;
}

.entry_display_car_img__clerb {
    max-height: 625px;
    max-width: 625px;
    margin: auto;
}

.entry_display_single_img__1op2F {
    max-height: 625px;
    max-width: 625px;
    margin: auto;
}

.entry_display_video__3l7rG {
    width: 625px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.entry_display_button__3HFKg {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    text-align: right;
}

.entry_display_button_voted__1IuYU {
    background-color: var(--PRIMARY_GOLD);
}

.entry_display_nav__17eRa {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    min-height: 100px;
}

.entry_display_nav__17eRa p {
    margin-bottom: 0;
}

.entry_display_nav_button_container_right__2TUYg {
    margin-left: auto;
    text-align: right;
}

.entry_display_button_label__3Pf7V {
    margin-bottom: 0;
}

.entry_display_nav_button__2qmzf {
    border: none;
    margin: 0;
    padding: 0;
    /* text-decoration: underline;
    text-decoration-color: var(--PRIMARY_GOLD); */
    background-color: white;
    border-bottom: 1px solid var(--PRIMARY_GOLD);
}

.entry_display_nav_button_right__2125E {
    text-align: right;
}

.entry_display_nav_button_left__27Ph7 {
    text-align: left;
}

.entry_display_close__8gqE4 {
    margin-bottom: 20px;
    text-align: right;
    margin-top: 10px;
    height: 24px;
}

@media screen and (max-width: 415px) {
    .entry_display_article_container__2-RVw {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-left: 0;
        align-items: flex-start;
    }

    .entry_display_article__1lIeP {
        margin-top: 20px;
    }

    .entry_display_arrow_next__22-Cv, .entry_display_arrow_prev__1C3vL {
        display: none;
    }

    .entry_display_img_holder__c7uP2, .entry_display_feature_img__3te01 {
        height: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
        width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }

    .entry_display_car_img__clerb {
        max-height: 100vw;
        max-width: 100vw;
    }

    .entry_display_single_img__1op2F {
        max-width: calc(100vw - (var(--CONTAINER_MOBILE_PADDING_RIGHT) * 2));
    }

    .entry_display_car_container__1b1xt {
        /* height: 100vw; */
        height: unset;
        width: 100vw;
        margin-right: unset;
    }

    .entry_display_container__3aAFp {
        padding: var(--CONTAINER_MOBILE_PADDING);
        width: 100vw;
    }

    .entry_display_button__3HFKg {
        position: static;
        margin-bottom: 10px;
    }

    .entry_display_header__2q9v8 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .entry_display_crowns__3HsBR {
        margin-top: 30px;
        height: 34px;
        position: static;
    }

    .entry_display_nav_button__2qmzf {
        max-width: 150px;
    }

}
/* FranklinGothic URW Book

font-family: franklin-gothic-urw, sans-serif;

font-weight: 400;

font-style: normal;


FranklinGothic URW Demi

font-family: franklin-gothic-urw, sans-serif;

font-weight: 700;

font-style: normal;


FranklinGothic URW Book Italic

font-family: franklin-gothic-urw, sans-serif;

font-weight: 400;

font-style: italic;


FranklinGothic URW Demi Italic

font-family: franklin-gothic-urw, sans-serif;

font-weight: 700;

font-style: italic; 




ITCFranklinGothic LT Pro CnBk

font-family: franklin-gothic-condensed, sans-serif;

font-weight: 400;

font-style: normal;


ITCFranklinGothic LT Pro CnBkIt

font-family: franklin-gothic-condensed, sans-serif;

font-weight: 400;

font-style: italic;


ITCFranklinGothic LT Pro CnDm

font-family: franklin-gothic-condensed, sans-serif;

font-weight: 600;

font-style: normal;


ITCFranklinGothic LT Pro CnDmIt

font-family: franklin-gothic-condensed, sans-serif;

font-weight: 600;

font-style: italic;

*/

.text_large_headline__2PmO4 {
    /* font-family: 'Libre Franklin', sans-serif; */
    /* font-family: 'test'; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
    /* color: pink; */
}

.text_medium_headline__12lpp {
    /* font-family: 'Libre Franklin', sans-serif; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
    margin-top:10px;
    /* color: green; */
}

.text_small_headline__1672T {
    /* font-family: 'Libre Franklin', sans-serif; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
    /* color: lightcoral; */
}

.text_extra_small_headline__1-lUq {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
    /* color: red; */
}

.text_ingress__2gZtq {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110%;
    color: var(--SECONDARY_TEXT_COLOR);
    /* color: orange; */
}

.text_plain_text__2luZp {
    /* font-family: 'Libre Franklin', sans-serif; */
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 135%;
    letter-spacing: 0.01em;
    word-spacing: 0.02em;
    color: var(--SECONDARY_TEXT_COLOR);
    /* color: peru; */
}

p, span, a {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 135%;
    letter-spacing: 0.01em;
    word-spacing: 0.02em;
    color: var(--SECONDARY_TEXT_COLOR);
    /* color: brown; */
}

.text_label__1nCKM {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 140%;
    letter-spacing: -0.01em;
    /* color: teal; */
}

a {
    border-bottom: 1px solid var(--PRIMARY_GOLD);
    height: 27px;
}

a:hover {
    text-decoration: none;
    color: inherit;
    opacity: 0.8;
}

h1, h2, h3, h4, h5, h6 {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    margin-top: 0;
    letter-spacing: -0.02em;
}

h1 {
    letter-spacing: -0.02em;
}




@media screen and (max-width: 415px) {
    .text_large_headline__2PmO4 {
        font-size: 44px;
    }

    .text_medium_headline__12lpp {
        
    }

    .text_small_headline__1672T {
        font-size: 23px;
    }
}

/* 
.large_headline {
    font-family: ITC Franklin Gothic Std Demi;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
}

.medium_headline {
    font-family: ITC Franklin Gothic Std Demi;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
    margin-top:10px;
}

.small_headline {
    font-family: ITC Franklin Gothic Std Demi;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
}

.extra_small_headline {
    font-family: ITC Franklin Gothic Std Demi;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_TEXT_COLOR);
}

.ingress {
    font-family: ITC Franklin Gothic Std Book;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: var(--SECONDARY_TEXT_COLOR);
}

.plain_text {
    font-family: ITC Franklin Gothic Std Book;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 140%;
    color: var(--SECONDARY_TEXT_COLOR);
}

.label {
    font-family: ITC Franklin Gothic Std Demi;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
}


@media screen and (max-width: 415px) {
    .large_headline {
        font-size: 48px;
    }

    .medium_headline {
        
    }

    .small_headline {
        font-size: 24px;
    }
} */
.close_button_button__Y1yzB {
    background-color: white;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px;
    margin: 0;
}

@media screen and (max-width: 415px) {
    .close_button_button__Y1yzB {
        position: fixed;
        top: 10px;
        right: var(--CONTAINER_MOBILE_PADDING_RIGHT);
        background-color: transparent;
    }

}
.video_player_container__1rpV3 {
    width: 100%;
    position: relative;
}

.video_player_sub_container__2_9uL {
    padding:56.25% 0 0 0;position:relative;
}

.video_player_vimeo_iframe__3QKB2 {
    position:absolute;top:0;left:0;width:100%;height:100%;
    border: none;
}
.carousel-control-next, .carousel-control-prev {
    top: unset !important;
    text-decoration: none !important;
    border-bottom: none !important;
}
.start_main__3YWZc {
    min-height: 100vh;
}

.start_winner_header__2Vjhb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
@-webkit-keyframes winner_feature_fadeIn__1U8M8 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes winner_feature_fadeIn__1U8M8 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.winner_feature_img__LtbdO {
  /* max-width: 700px; */
  margin-left: 155px;
  max-height: 544px;
  -webkit-animation: winner_feature_fadeIn__1U8M8 2000ms;
          animation: winner_feature_fadeIn__1U8M8 2000ms;
}

.winner_feature_img_placeholder__1fsRq {
  max-height: 544px;
  -webkit-animation: winner_feature_fadeIn__1U8M8 2000ms;
          animation: winner_feature_fadeIn__1U8M8 2000ms;
  background-color: #f5f5f5;
  cursor: pointer;
}

.winner_feature_text_placeholder__2IROC {
  width: 300px;
  height: 156px;
  margin-left: 29px;
  /* background-color: #F5F5F5; */
}

.winner_feature_section__9Yj76 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 544px;
  margin-bottom: 94px;
}

.winner_feature_text_container__1_MEv {
  margin-left: 29px;
  width: 400px;
  -webkit-animation: winner_feature_fadeIn__1U8M8 2000ms;
          animation: winner_feature_fadeIn__1U8M8 2000ms;
}

.winner_feature_text_container__1_MEv p {
  margin-bottom: 0;
}

@media screen and (max-width: 1145px) {
  .winner_feature_text_container__1_MEv {
    margin-left: 155px;
  }
  .winner_feature_text_placeholder__2IROC {
    margin-left: 155px;
  }
}

@media screen and (min-width: 860px) {
  .winner_feature_img_placeholder__1fsRq {
    position: relative;
    left: 155px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 860px) {
  .winner_feature_img__LtbdO {
    object-fit: contain;
    width: 100%;
    margin-left: unset;
  }
  .winner_feature_img_placeholder__1fsRq {
    width: 100%;
    margin-left: unset;
  }
  .winner_feature_text_container__1_MEv {
    width: 100%;
    margin-left: unset;
  }
  .winner_feature_text_placeholder__2IROC {
    width: 100%;
    margin-left: unset;
  }
}

@media screen and (max-width: 415px) {
  .winner_feature_section__9Yj76 {
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: unset;
  }

  .winner_feature_img__LtbdO {
    height: 226px;
    width: unset;
    margin-bottom: 32px;
  }

  .winner_feature_img_placeholder__1fsRq {
    width: 100%;
    margin-bottom: 32px;
    justify-content: center;
  }

  .winner_feature_text_container__1_MEv {
    width: unset;
    margin-left: 0;
  }
  .winner_feature_headline__3KcA6 {
    font-size: 32px;
  }
}

.start_content_section__xc2Mh h1 {
    color: var(--PRIMARY_GOLD);
    font-size: 60px;
    line-height: 95%;
}

.start_content_content__2_d3B {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 150px;
}

.start_content_content__2_d3B p {
    margin-right: 20px;
    max-width: 400px;
    min-width: 300px;
    width: 40%;
}

.start_content_content__2_d3B div {
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .start_content_content__2_d3B {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 415px) {
    .start_content_content__2_d3B {
        margin-bottom: 55px;
    }
    .start_content_section__xc2Mh h1 {
        font-size: 11vw;
    }
    .start_content_content__2_d3B p {
        width: unset;
        min-width: unset;
    }

    .start_content_content__2_d3B div {
        margin: 10px 0 10px 0;
    }
}

.share_button_button__1SfN0 {
    border:none;
    outline: none;
    opacity: 1;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    margin-bottom: 20px;
    background-color: rgba(0,0,0,0);
}

.share_button_button__1SfN0:focus {
    outline: none;
}

.share_button_button__1SfN0:hover {
    opacity: 0.5;
}

.share_button_share_buttons__2Z0ml {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 64px;
    height: 180px;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    -webkit-filter: grayscale();
            filter: grayscale();
}

.share_button_share_buttons_show__kYxiY {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: top;
            transform-origin: top;
}

.share_button_container__1HEmn {
    position: absolute;
    right: var(--CONTAINER_PADDING_RIGHT);
    text-align: right;
    z-index: 100;
}

.share_button_spacer__33MNt {
    height: 40px;
}

@media screen and (max-width: 415px) {
    .share_button_container__1HEmn {
        right: var(--CONTAINER_MOBILE_PADDING_RIGHT);
        
    }

    .share_button_button__1SfN0 {
        height: 30px;
        position: relative;
        top: -10px;
    }

    .share_button_icon__326Z6 {
        height: 22px;
    }
    
}
.puff_container__2fxNw {
    min-height: 368px;
    margin-bottom: 126px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0px 75px 0px 75px;
    max-width: 1400px;
    position: relative;
    left: -80px;
}

.puff_calendar__2OxCG {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;
    flex-grow: 1;
}

.puff_calendar__2OxCG > div:nth-child(1) {
    color: black;
    margin-right: 70px;
}

.puff_calendar__2OxCG > div:nth-child(2) {
    color: white;
}
.puff_calendar__2OxCG > div:nth-child(1) > p {
    /* color: white; */
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.puff_calendar__2OxCG > div:nth-child(2) > p {
    color: white;
    line-height: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.puff_left__1pV_O {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.puff_right__2uFjj {
    text-align: center;
}

.puff_black__21xkl {
    background-color: black;
}

.puff_gold__pGtCV {
    background-color: var(--PRIMARY_GOLD);
}

.puff_day__otoPJ {
    font-size: 270px;
    line-height: 73%;
    margin: 0;
    letter-spacing: -0.02em;
}

.puff_month__2_nEB {
    margin: 0px;
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: 53px;
    line-height: 100%;
}

.puff_text__3QkPL {
    margin: 0px;
    margin-right: 10px;
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    /* max-width: 250px; */
    min-width: 200px;
    line-height: 100%;
}

.puff_award_text__2aAO1 {
    max-width: 300px;
}

.puff_text_container__2y85p {
    flex-grow: 1;
}

.puff_text_container_left__BVSWc {
    margin-left: 15px;
    flex-grow: 1;
}

.puff_black_text__CwYJO {
    color: #1C1B19;
}

.puff_gold_text__2FudC {
    color: var(--PRIMARY_GOLD);
}

.puff_ticket_button__1BETy {
    border: 1px solid var(--PRIMARY_GOLD);
    color: var(--PRIMARY_GOLD);
    background-color: black;
    height: 190px;
    max-width: 407px;
    width: 25vw;
    min-width: 200px;
    font-weight: 700;
}

.puff_right_button__89Vbb {
    background-color: rgba(0,0,0,0);
    border: none;
}

.puff_vote_headline__3UWkf {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 180px;
    letter-spacing: -0.03em;
    margin-right: 5px;
    line-height: 73%;
}

.puff_vote_date__2VnMI {
    
}

.puff_vote_text__1uRFA {
    min-width: 100px;
}

@media screen and (max-width: 1274px) {
    .puff_vote_headline__3UWkf {
        font-size: 150px;
        top: 92px;
    }
}

@media screen and (max-width: 1305px) {
    .puff_vote_headline__3UWkf {
        
    }
    .puff_container__2fxNw {
        padding-bottom: 75px;
        /* justify-content: sp; */
    }
    .puff_left__1pV_O {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .puff_ticket_button__1BETy {
        max-width: 407px;
        width: 407px;
        min-width: 200px;
    }
}

@media screen and (max-width: 415px) {
    .puff_container__2fxNw {
        position: static;
        width: 100%;
        justify-content: flex-start !important;
    }

    .puff_calendar__2OxCG > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .puff_calendar__2OxCG > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .puff_calendar__2OxCG > div:nth-child(1) > p {
        /* color: white; */
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .puff_calendar__2OxCG > div:nth-child(2) > p {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .puff_text_container_left__BVSWc {
        margin-left: 0px;
    }

    .puff_vote_headline__3UWkf {
        position: relative;
        left: -7px !important;
    }
    .puff_day__otoPJ {
        position: relative;
        left: -15px;
    }
}

@media screen and (max-width: 750px) {
    .puff_container__2fxNw {
        justify-content: center;
        padding: 0px 39px 0px 39px;
        margin-bottom: 60px;
    }
    .puff_left__1pV_O {
        flex-wrap: wrap;
    }
    .puff_day__otoPJ {
        font-size: 240px;
        margin-bottom: 30px;
    }
    .puff_month__2_nEB {
        font-size: 48px;
    }
    .puff_text__3QkPL {
        font-size: 24px;
        max-width: unset;
    }
    .puff_text_container__2y85p {
        bottom: 0
    }
    .puff_ticket_button__1BETy {
        max-width: unset;
        width: 257px;
        min-width: unset;
    }
    .puff_vote_headline__3UWkf {
        font-size: 107px;
        line-height: 140px;
        top: 40px;
    }
    .puff_right__2uFjj {
        margin-bottom: 67px;
    }
}



.header_placeholder__2h-J9 {
    position: relative;
    height: 350px;
}

.header_container__1-oTo {
    height: 250px;
    max-height: 250px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    background-color: rgba(255,255,255,0);
    z-index: 1000;
}

.header_container_minimized__3CPbt {
    /* height: 150px; */
    max-height: 150px;
    overflow: hidden;
}

.header_logo_minimized__1xx_F {
    opacity: 0;
}

.header_logo_container_maximized__3564G {
    max-height: 126px;
    top: -50px;
}

.header_logo_container_minimized__aXRz8 {
    top: 0;
}

.header_logo_container__1xkKv {
    position: relative;
    width: 82px;
    max-height: 54px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    cursor: pointer;
}

.header_logo__fZCbh {
    width: 82px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
}

.header_crown__1ahRH {
    position: absolute;
    width: 81px;
    top: 0;
    left: 0;
}

.header_border__1ci62 {
    flex-grow: 1;
    height: 2px;
    display: flex;
    justify-content: center;
    margin: 0 73px 0 73px;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 415px) {
    .header_container__1-oTo {
        height: 140px;
    }

    .header_container_minimized__3CPbt {
        height: 100px;
    }

    .header_border__1ci62 {
        /* width: 60px; */
        margin: 0 30px 0 30px;
    }

    .header_logo_container_maximized__3564G {
        max-height: 60px;
        top: -25px;
    }
    
    .header_logo_container__1xkKv {
        width: 38px;
        max-height: 23px;
    }

    .header_logo__fZCbh {
        width: 38px;
    }

    .header_crown__1ahRH {
        width: 38px;
    }

    .header_button__2VTFT {
        height: 36px;
        line-height: 15px;
        font-size: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        min-width: 136px;
    }
}
.footer_footer__30i7I {
    margin-top: 100px;
}

.footer_logo__jYwil {
    width: 89px;
}

.footer_logo_container__1jJLt {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}
.footer_border__1b3ZC {
    flex-grow: 1;
    border-bottom: 1px solid black;
    margin: 0 0 0 73px;
}
.footer_container__1MSPJ {
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 80px;
    background-color: var(--PRIMARY_GOLD);
}

.footer_nav__1WQ3h {
    /* width: 60%; */
    flex-grow: 4;
    margin-bottom: 50px;
}

.footer_link__23vw8 {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 140%;
}

.footer_link__23vw8:hover {
    text-decoration: none;
    opacity: 0.8;
}

.footer_container__1MSPJ a {
    display: block;
}

.footer_content_container__1dJ4C {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
}

.footer_content_container__1dJ4C > div:nth-child(1) {
    margin-bottom: 50px;
}

.footer_content_container__1dJ4C a {
    border-bottom: 1px solid black;
    line-height: 19px;
    margin-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 23px;
}

@media screen and (max-width: 415px) {
    .footer_logo__jYwil {
        width: 48px;
    }

    .footer_container__1MSPJ {
        padding: 39px;
    }

    .footer_border__1b3ZC {
        margin: 0 0 0 33px;   
    }

    .footer_logo_container__1jJLt {
        height: 73px;
    }
}
.dev_header_container__2WPX1 {
    /* width: 100vw; */
    background-color: cadetblue;
    text-align: center;
}

.dev_header_selected__2xz6p {
    background-color: chocolate;
}
.navigation_container__vplhy {
    padding: var(--CONTAINER_PADDING);
    /* width: 100vw; */
}

@media screen and (max-width: 415px) {
    .navigation_container__vplhy {
        padding: var(--CONTAINER_MOBILE_PADDING);
    }
}
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&display=swap'); */


:root {
    --TRANSITION_EASE_OUT: cubic-bezier(0.17, 0.67, 0.3, 1.01);
    --TRANSITION_DURATION: 250ms;
    --PRIMARY_SHADOW: 0px 3px 5px 0px rgba(0,0,0,0.31);
  
    --PRIMARY_GOLD: #c6a230;
    --BUTTON_GOLD: #C29D25;
    --PRIMARY_TEXT_COLOR: #000000;
    --SECONDARY_TEXT_COLOR: #151313;
    --LIGTH_TEXT_COLOR: #707070;

    --MOBILE_WIDTH: 415px;

    --PRIMARY_BORDER_COLOR: #707070;

    --CONTAINER_PADDING: 0 80px 0 80px;
    --CONTAINER_MOBILE_PADDING: 0px 20px 0 20px; 
    --CONTAINER_PADDING_RIGHT: 80px;
    --CONTAINER_MOBILE_PADDING_RIGHT: 20px;

    --PRIMARY_HEADER_FONT: franklin-gothic-urw, sans-serif;
    --PRIMARY_TEXT_FONT: franklin-gothic-urw, sans-serif;
}

html {
    scroll-padding-top: 200px;
}


hr {
    border-color: #1C1B19;
}

body {
    max-width: 1400px;
}

#root {
    position: absolute;
    max-width: 1400px;
    left: 0;
    right: 0;
    margin: auto;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

