.modal {
    border-radius: 0;
    max-width: fit-content;
}

.modal div {
    border-radius: 0;
    max-width: 1280px;
}

@media screen and (max-width: 415px) {
    .modal {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
        padding: 0;
    }
    .modal div {
        border: none;
    }
}